.style{
  display: flex;
  flex-direction: column;
}
.App{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.input-label{ 
  padding: 5px;
  display: flex;
  flex-direction: column;
  
}
.filds{
  border-color: #2A44F1;
  background: #FFFFFF;
  border-radius: 8px;
  height: 44px;
}

.filds-name{
  padding-bottom: 5px;
}

.btn{
  cursor: pointer;
  font-size: 25px;
  color: #FFFFFF;
  width: 100%;
  border-radius: 8px;
  border: 0px;
  height: 60px;
  background: #2A44F1;
}

.btn-c{
  padding-top: 50px;
  width: 100%;
}

body{
  padding: 0;
  margin: 0;
}

html{
  height: 100%;
}

.container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
}

.logo{
width: 50px;
height: 50px;
}
.logo-contain{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.page-name{
  color: #F6649A;
  font-family: inherit
}

.h1{
  font-family: inherit;
   
}

.shownumbers{
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background: #FFFFFF;
}

.h1{
  
  font-size: 24px !important;
}

.input-range{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: 170px;
}

.score_container{
  padding-top: 15px;
  max-width: 395px;
  justify-content: center;
  display: flex;
  align-items: flex-start;
  flex-direction: row;

}

.scoresss{
  padding: 5px;
  max-width: 190px;
}

.li{
  width: 100%;
  padding: 5px;
}
.in{
  border: #FFFFFF;
  width: max-content;
}

.h2{
  font-size: 18px;
}

.span{
 
  overflow-wrap: anywhere;
  margin-top: 20px;
}